import { createSlice } from "@reduxjs/toolkit"
import { CaseReducerType } from "store/store"
import { Components } from 'api/kyc/generated/client'

type ReviewStatusType = Components.Schemas.ReviewStatusType
type FormType = string
type FormUpdatedType = {
    updated: boolean,
    form: FormType[],
    formName?: string,
    action?: any
}
type EditedConfirmType = {
	onConfirm?: any,
	onConfirmLink?: string,
	showErrors?: boolean,
	id?: string,
  	general?: boolean
}
type EditedFormType = {
    values?: any,
    type?: string
}
type NavType = {
    next?: string,
    step?: number,
    substep?: number,
    refId?: string,
    nextName?: string
    nextButton?: string
    prev?: string
    prevName?: string,
    status?: ReviewStatusType,
    hideNav?: boolean,
    navPage?: string,
    navSubpage?: string,
    paginationPage?: string | number,
    activeListingTab?: string,
	adminEditing?: boolean,
    sorters?: any,
    filters?: any,
    link?: string
}

type State = {
	nav: NavType,
	formUpdated: FormUpdatedType,
	currentEditedForm: EditedFormType,
	editedConfirm: EditedConfirmType,
	isAdminEditing: boolean
  
}

const initialState: State = {
	nav: {
		next: '',
		step: 0,
	},
	formUpdated: {
		updated: false,
		form: [],
		action: undefined,
		formName: undefined
	},
	currentEditedForm: {
		type: '',
		values: {}
	},
	editedConfirm: {},
	isAdminEditing: false
}

type CaseReducers<State> = {
	setIsAdminEditing: CaseReducerType<State, boolean>;
  	setNav: CaseReducerType<State, NavType>;
  	setFormUpdated: CaseReducerType<State, FormUpdatedType>;
  	setCurrentEditedForm: CaseReducerType<State, EditedFormType>;
  	setEditedFormConfirmed: CaseReducerType<State, EditedConfirmType>;
}

export const navSlice = createSlice<State, CaseReducers<State>>({
  	name: "navEl",
  	initialState,
	reducers: {
		setIsAdminEditing: (state, { payload }) => {
			state.isAdminEditing = payload
		},
		setNav: (state, { payload }) => {
			state.nav = payload
		},
		setFormUpdated: (state, { payload }) => {
			state.formUpdated.updated = payload.updated  
			state.formUpdated.formName =  payload.formName
			state.formUpdated.action = payload.action
			if(payload.updated === true) {
				state.formUpdated.form.push(...payload.form)
			} else {
				state.formUpdated.form = []
			}
		
		},
		setEditedFormConfirmed: (state, { payload }) => {
			state.editedConfirm = payload
		
		},
		setCurrentEditedForm: (state, { payload }) => {
			state.currentEditedForm = payload
		
		}
	}
})

export const { setNav, setIsAdminEditing, setFormUpdated, setCurrentEditedForm, setEditedFormConfirmed } = navSlice.actions

export default navSlice.reducer
